
import { onMounted, computed, useContext } from '@nuxtjs/composition-api'
import { useProduct } from '~/composables'
import { GtagItemListActions, sendGtagItemListEvent } from '~/modules/aatrium-gtm'

import GridOfCards from '~/components/molecules/GridOfCards.vue'
import ProductsCarousel from '~/modules/catalog/product/components/ProductsCarousel.vue'
import { preparedGtagItems } from '~/modules/aatrium-gtm/helpers'

export default {
  name: 'CategoryProducts',
  components: {
    GridOfCards,
    ProductsCarousel,
    ProductsCarouselSkeleton: () => import('../skeletons/ProductsCarouselSkeleton.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => ([])
    },
    seeMore: {
      type: Object,
      default: () => ({ text: '', link: '' })
    },
    withBorder: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { loading } = useProduct()
    const { app } = useContext()

    const redirectLink = computed<string>(() => {
      if (props.seeMore?.link) {
        return props.seeMore.link.startsWith('/')
          ? app.localePath(props.seeMore.link)
          : (props.seeMore.link.startsWith('http')
              ? props.seeMore.link
              : app.localePath('/' + props.seeMore.link))
      }

      return ''
    })

    onMounted(() => {
      sendGtagItemListEvent(GtagItemListActions.ViewItemList, {
        items: preparedGtagItems(props.products.map(product => ({ product }))),
        item_list_name: props.title
      })
    })

    return {
      loading,
      redirectLink
    }
  }
}
