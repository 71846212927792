import { computed, Ref, ref } from '@nuxtjs/composition-api'
import { Swiper } from 'swiper/types'
import { UseSwiper } from '~/composables/useSwiper/types'

export function useSwiper (): UseSwiper {
  const swiper = ref<Swiper>(null)

  function setInstance (instance: Swiper): void {
    swiper.value = instance
  }

  function changeSlide (index: number): void {
    swiper.value?.slideToLoop(index)
  }

  function goToPrev (): void {
    swiper.value?.slidePrev()
  }

  function goToNext (): void {
    swiper.value?.slideNext()
  }

  return {
    swiper: swiper as Readonly<Ref<Swiper>>,
    activeSlideIndex: computed(() => swiper.value?.realIndex),
    isBeginning: computed<boolean>(() => swiper.value?.isBeginning),
    isEnd: computed<boolean>(() => swiper.value?.isEnd),
    setInstance,
    changeSlide,
    goToPrev,
    goToNext
  }
}
